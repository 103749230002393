<template>
  <b-card no-body>
    <b-row class="p-2">
      <b-col sm="8">
        <h4>
          {{ branchData.name }}
        </h4>
        <b-badge
          v-if="branchData.status"
          pill
          :variant="`light-${resolveStatusVariant(branchData.status)}`"
          class="text-capitalize"
        >
          {{ resolveStatus(branchData.status) }}
        </b-badge>
      </b-col>

      <b-col sm="4">
        <b-button
          v-if="$ability.can('E', 'Retailers & Branches') && branchData.branchId"
          size="sm"
          :to="{ name: 'retailers-management-retailer-branches-edit', params: { id: branchData.branchId } }"
          variant="primary"
          class="float-right"
        >
          Edit
        </b-button>
      </b-col>
    </b-row>

    <b-row class="px-1">
      <b-col sm="12">

        <b-list-group flush>

          <!-- CR Number -->
          <b-list-group-item
            v-if="branchData.CRNumber"
            class="border-0">
            <b-row>
              <b-col sm="8">
                <strong>CR Number</strong>
              </b-col>
              <b-col sm="4">
                {{ branchData.CRNumber }}
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Activity -->
          <b-list-group-item
            v-if="branchData.activity && branchData.activity.description "
            class="border-0"
          >
            <b-row>
              <b-col sm="8">
                <strong>Activity</strong>
              </b-col>
              <b-col sm="4">
                {{ branchData.activity.description }}
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Sub Activity -->
          <b-list-group-item
            v-if="branchData.subactivity && branchData.subactivity.description "
            class="border-0"
          >
            <b-row>
              <b-col sm="8">
                <strong>Sub Activity</strong>
              </b-col>
              <b-col sm="4">
                {{ branchData.subactivity.description }}
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Require Fresh Catalogue -->
          <b-list-group-item
            v-if="branchData.requireFreshCatalog"
            class="border-0"
          >
            <b-row>
              <b-col sm="8">
                <strong>Catalogue Type</strong>
              </b-col>
              <b-col sm="4">
                {{ branchData.requireFreshCatalog == 0 ? 'Custom Catalogue' : 'Fresh Catalogue' }}
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Custom Catalogue URL -->
          <b-list-group-item
            v-if="branchData.customerCustomCatalogUrl"
            class="border-0"
          >
            <b-row>
              <b-col sm="8">
                <strong>Custom Catalogue URL</strong>
              </b-col>
              <b-col sm="4">
                {{ branchData.customerCustomCatalogUrl }}
              </b-col>
            </b-row>
          </b-list-group-item>

        </b-list-group>

        <!-- Latitude & Longitude -->
        <display-google-map
          v-if="branchData.latitude && branchData.longitude"
          :draggable="false"
          :position="{ lat: parseFloat(branchData.latitude), lng: parseFloat(branchData.longitude) }"
        />

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem, BBadge, BButton,
} from 'bootstrap-vue'
// import useRetailersList from '../../retailers-list/useRetailersList'
import DisplayGoogleMap from '@/components/google-maps/display-google-map/DisplayGoogleMap.vue'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,

    DisplayGoogleMap,
  },
  props: {
    branchData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // const {
    //   resolveStatus,
    //   resolveStatusVariant,
    // } = useRetailersList()

    return {
      resolveStatus,
      resolveStatusVariant,
    }
  },
}
</script>
