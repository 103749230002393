<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="branchData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching branch data
      </h4>
      <div class="alert-body">
        No branch found with this branch id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'retailers-management-retailers-list'}"
        >
          Retailer List
        </b-link>
        for other retailers.
      </div>
    </b-alert>

    <template v-if="branchData && branchData.name">

      <!-- Branch Info -->
      <b-row>
        <b-col>

          <branch-view-info-card :branch-data="branchData" />

        </b-col>
      </b-row>

      <b-card
        v-if="branchData.cashiers && branchData.cashiers.length > 0"
        no-body
      >
        <b-row>

          <b-col class="mt-2">

            <h4 class="px-2 mb-2">
              Cashiers
            </h4>

            <cashiers-list :cashiers="branchData.cashiers" />

          </b-col>
        </b-row>
      </b-card>

      <b-card
        v-if="branchData.products && branchData.products.length > 0"
        no-body
      >
        <b-row>

          <b-col class="mt-2">

            <h4 class="px-2 mb-2">
              Devices
            </h4>

            <devices-list
              :devices="branchData.products"
              :table-columns="tableColumns"
            />

          </b-col>
        </b-row>
      </b-card>

    </template>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import BranchViewInfoCard from './BranchViewInfoCard.vue'
import retailerStoreModule from '../../retailerStoreModule'
import useBranchesForm from '../branches-form/useBranchesForm'
import CashiersList from '../../cashiers/cashiers-list/CashiersList.vue'
import DevicesList from '../../devices/devices-list/DevicesList.vue'
import useDevicesList from '../../devices/devices-list/useDevicesList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    BranchViewInfoCard,
    CashiersList,
    DevicesList,
  },
  setup() {
    const RETAILER_APP_STORE_MODULE_NAME = 'app-retailer'

    // Register module
    if (!store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.registerModule(RETAILER_APP_STORE_MODULE_NAME, retailerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.unregisterModule(RETAILER_APP_STORE_MODULE_NAME)
    })

    const {
      branchData,

      fetchBranch,
    } = useBranchesForm()

    const {
      tableColumnsWithoutAction: tableColumns,
    } = useDevicesList()

    fetchBranch(router.currentRoute.params.id)

    return {
      branchData,

      tableColumns,
    }
  },
}
</script>
