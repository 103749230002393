<template>
  <google-map :position="position">
    <template v-slot:marker>
      <google-map-marker
        :draggable="draggable"
        :position="position"
      />
    </template>
  </google-map>
</template>

<script>
import GoogleMap from '../GoogleMap.vue'
import GoogleMapMarker from '../GoogleMapMarker.vue'

export default {
  components: { GoogleMap, GoogleMapMarker },
  props: {
    position: {
      type: Object,
      required: true,
    },
    draggable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
